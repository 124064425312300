<template>
  <md-dialog
    :md-active="open"
    class="modalEdit__instructors"
    @close="closeModal"
  >
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">EDITAR USUARIO</h3>
      </div>
      <div class="form-instructor dialog-body">
        <div class="row">
          <div class="col-12">
            <base-input
              v-model="formData.name"
              :value="formData.name"
              name="nombre"
              placeholder="Nombre"
              required
              type="text"
            ></base-input>
          </div>
          <div class="col-12">
            <base-input
              :value="formData.email"
              disabled
              name="email"
              placeholder="Email"
              type="text"
            ></base-input>
          </div>
          <div class="col-12">
            <base-input
              v-model="formData.phone"
              :value="formData.phone"
              name="phone"
              placeholder="Teléfono"
              required
              type="text"
            ></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2">
            <md-field class="mb-0 mt-0">
              <multiselect
                v-model="formData.country"
                :close-on-select="true"
                :options="getAllCountries"
                :searchable="true"
                :show-labels="false"
                class="select-danger details-country v-select"
                label="name"
                placeholder="Seleccione un País"
                track-by="id"
              >
                <template #noResult>
                  <div>Sin resultados</div>
                </template>
              </multiselect>
            </md-field>
          </div>
          <div class="col-md-12 mt-2">
            <md-field class="mb-0 mt-0">
              <multiselect
                v-model="formData.status"
                :close-on-select="true"
                :options="StatusUser"
                :searchable="true"
                :show-labels="false"
                class="select-danger details-country v-select"
                placeholder="Cambiar Status"
                label="label"
                track-by="name"
              >
                <template #noResult>
                  <div>Sin resultados</div>
                </template>
                <template #singleLabel="{ option }">
                  <span>{{ getOptionLabel(option) }}</span>
                </template>
              </multiselect>
            </md-field>
          </div>
        </div>
      </div>
      <md-dialog-actions class="modal-footer">
        <base-button
          class="md-primary w-auto"
          type="secondary"
          @click="closeModal"
          >CANCELAR
        </base-button>
        <base-button
          :loading="isLoading"
          class="md-primary w-auto"
          type="primary"
          @click="updateUser"
          >ACTUALIZAR
        </base-button>
      </md-dialog-actions>
    </div>
  </md-dialog>
</template>

<script>
import BaseButton from '../../../../../components/BaseButton';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import validator from 'validator';
import { UPDATE_USER } from '../../../../../store/actions/user';

export default {
  name: 'UserDialog',
  components: {
    BaseButton,
    Multiselect,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: Object,
      default: () => undefined,
    },
  },
  emits: ['close-modal', 'success'],
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        country: '',
        status: '',
      },
      isLoading: false,
      StatusUser: [
        { name: 'ACTIVE', label: 'Activo' },
        { name: 'INACTIVE', label: 'Inactivo' },
        { name: 'UNVERIFIED', label: 'No verificado' },
      ],
    };
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
    ...mapGetters(['getAllCountries']),
    getOptionLabel() {
      return (option) => {
        switch (option) {
          case 'ACTIVE':
            return 'Activo';
          case 'INACTIVE':
            return 'Inactivo';
          case 'UNVERIFIED':
            return 'No verificado';
          default:
            return option.label;
        }
      };
    },
  },
  watch: {
    sortType: function (newValue) {
      this.fetchUsers({
        search: this.searchValue,
        per_page: 12,
        order_by: newValue,
      });
    },
    selectedUser: function (user) {
      if (user && user.id) {
        this.formData = {
          id: user.id,
          name: user.name,
          email: user.email,
          phone: user.phone,
          country: user.country,
          status: user.status,
        };
      }
    },
  },
  methods: {
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    updateUser() {
      this.isLoading = true;
      if (!validator.default.isEmail(this.formData.email)) {
        return this.notify('danger', 'EL formato del correo es incorrecto');
      }

      const data = {
        id: this.formData.id,
        name: this.formData.name,
        email: this.formData.email,
        phone: this.formData.phone,
        country_id:
          this.formData.country && this.formData.country.id
            ? this.formData.country.id
            : '',
        status:
          this.formData.status && this.formData.status.name
            ? this.formData.status.name
            : '',
      };
      this.$store
        .dispatch(UPDATE_USER, data)
        .then(() => {
          this.isLoading = false;
          this.$emit('success');
          this.closeModal();
          this.notify('success', 'El usuario se ha actualizado correctamente');
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para actualizar el usuario');
          this.isLoading = false;
        });
    },
    reset() {
      this.formData = {
        name: '',
        email: '',
        password: '',
        repeatPassword: '',
        number: '',
        country: '',
      };
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },
  },
};
</script>

<style>
.multiselect__select:before,
.select-category-products .multiselect__select:before {
  top: 35% !important;
}
</style>
