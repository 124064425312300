<template>
  <div class="container-fluid">
    <div>
      <div
        class="col-12 row justify-content-between container-partners__search pr-0 pl-0 ml-0 mb-3"
      >
        <div
          class="col-xs-mobile col-6 col-sm-6 col-md-7 col-lg-7 col-xl-7 pl-0"
        >
          <form class="d-flex listUser-mobile">
            <i class="fas fa-search fa-flip-horizontal"></i>
            <input
              v-model="searchValue"
              aria-label="Search"
              class="form-control inputFilter md-filter-input me-2"
              placeholder="Buscar por nombre o correo electrónico"
              type="text"
              @keydown="searchUser"
            />

            <div class="md-layout md-gutter">
              <div class="md-layout-item" style="padding: 0px !important">
                <md-field class="sort-box filter-user">
                  <md-select
                    v-model="sortType"
                    class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                  >
                    <md-option hidden selected value="">Filtrar por</md-option>
                    <md-option value="DESC">Nuevo</md-option>
                    <md-option value="ASC">Antiguo</md-option>
                    <md-option value="ASC_name">A-Z</md-option>
                    <md-option value="DESC_name">Z-A</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </form>
        </div>
      </div>
      <validation-observer ref="formValidator">
        <div class="container-partners">
          <md-table>
            <md-table-row class="container-partners__head">
              <md-table-head>Nombre</md-table-head>
              <md-table-head>Estado</md-table-head>
              <md-table-head>País</md-table-head>
              <md-table-head>Ordenes</md-table-head>
              <md-table-head>Correo electrónico</md-table-head>
              <md-table-head>Telefono</md-table-head>
              <md-table-head>Tipo de Usuario</md-table-head>

              <md-table-head></md-table-head>
            </md-table-row>

            <md-table-row
              v-for="user in getUsers.items"
              :key="user.id"
              class="container-partners__cell"
            >
              <md-table-cell>{{ user.name }}</md-table-cell>
              <md-table-cell>
                <!-- registrado = activo -->
                <badge
                  v-if="user.status === userStatusOptions.USER_STATUS_ACTIVE"
                  class="status-active"
                  type="success"
                  >ACTIVO
                </badge>
                <badge
                  v-if="user.status === userStatusOptions.USER_STATUS_INACTIVE"
                  class="status-inactive"
                  type="secondary"
                  >INACTIVO
                </badge>
                <badge
                  v-if="
                    user.status === userStatusOptions.USER_STATUS_UNVERIFIED
                  "
                  class="status-pending"
                  type="secondary"
                  >NO VERIFICADO
                </badge>
              </md-table-cell>
              <md-table-cell v-if="user.country">
                {{ user.country.name }}
              </md-table-cell>
              <md-table-cell v-else>
                {{ 'SIN ASIGNAR' }}
              </md-table-cell>
              <md-table-cell>
                <div class="d-flex flex-row bd-highlight">
                  <div
                    v-if="user.orders_count >= 0"
                    :class="[
                      user.orders_count >= 1
                        ? 'md-avatar coursesActives'
                        : 'bg-light',
                    ]"
                    class="rounded-circle px-2 py-1 mr-1 ml-1"
                  >
                    {{ user.orders_count }}
                  </div>
                  <div v-if="user.orders_count >= 0" class="py-1">
                    <i
                      class="fas fa-bars"
                      role="button"
                      @click="handleUserOrders(user.id, user.orders_count)"
                    ></i>
                  </div>
                </div>
              </md-table-cell>
              <md-table-cell>{{ user.email }}</md-table-cell>
              <md-table-cell>{{ user.phone }}</md-table-cell>
              <md-table-cell v-for="role in user.roles" :key="role.id">
                {{ getRoleLabel(role.name) }}
              </md-table-cell>

              <md-table-cell class="container-partners__options">
                <span class="float-right">
                  <md-menu
                    :md-offset-x="-147"
                    :md-offset-y="-10"
                    md-size="auto"
                  >
                    <md-button md-menu-trigger>•••</md-button>

                    <md-menu-content>
                      <md-menu-item
                        v-if="
                          user.status ===
                          userStatusOptions.USER_STATUS_UNVERIFIED
                        "
                        :disabled="isValidDate(user.expiration)"
                        type="primary"
                        @click="resendUserVerification($event, user.email)"
                      >
                        Renovar Invitación
                      </md-menu-item>

                      <md-menu-item
                        v-if="
                          user.status ===
                          userStatusOptions.USER_STATUS_UNVERIFIED
                        "
                        type="primary"
                        @click="openDeleteUserDialog(user)"
                      >
                        Eliminar
                      </md-menu-item>

                      <md-menu-item
                        v-if="
                          user.status === userStatusOptions.USER_STATUS_ACTIVE
                        "
                        type="primary"
                        @click="openUserDialog(user)"
                        >Editar</md-menu-item
                      >
                    </md-menu-content>
                  </md-menu>
                </span>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <div
            v-if="totalPages > 1"
            class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
          >
            <base-pagination
              :value="page"
              class="pagination-style"
              :page-count="totalPages"
              @input="paginationChange"
            ></base-pagination>
          </div>
        </div>
      </validation-observer>
    </div>

    <delete-dialog
      :is-open="isOpenDeleteUser"
      @deleted="deleteUser"
      @close-modal="closeDeleteUserDialog"
      @success="refetchUsers"
    />
    <user-dialog
      :is-open="isOpenUpdateUser"
      :selected-user="selectedUser"
      @close-modal="closeUserDialog"
      @success="refetchUsers"
    />
  </div>
</template>
<script>
import { RESEND_VERIFICATION } from '@/store/actions/register';
import { mapGetters } from 'vuex';
import { DELETE_USER, FETCH_USERS } from '../../../../store/actions/user';
import DeleteDialog from '../components/DeleteDialog';
import UserDialog from './components/UserDialog';
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_INACTIVE,
  USER_STATUS_UNVERIFIED,
} from '../../../../util/constants';
import BasePagination from '../../../../components/BasePagination';

export default {
  name: 'UserView',
  components: {
    DeleteDialog,
    UserDialog,
    BasePagination,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data: () => ({
    searchValue: '',
    sortType: '',
    descOrder: 'DESC',
    isLoading: true,
    isOpenDeleteUser: false,
    isOpenUpdateUser: false,
    selectedUser: undefined,
    userStatusOptions: {
      USER_STATUS_ACTIVE,
      USER_STATUS_INACTIVE,
      USER_STATUS_UNVERIFIED,
    },
    page: 1,
    id: '',
  }),
  computed: {
    ...mapGetters(['getUsers', 'getAllCountries']),
    totalPages() {
      return Math.ceil(this.getUsers.total / this.perPage);
    },
  },
  watch: {
    sortType: function (newValue) {
      this.fetchUsers({
        search: this.searchValue,
        per_page: 20,
        order_by: newValue,
      });
    },
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    searchUser(e) {
      if (e.key === 'Enter') {
        this.refetchUsers();
      }
    },
    closeDeleteUserDialog() {
      this.selectedUser = undefined;
      this.isOpenDeleteUser = false;
    },
    openDeleteUserDialog(user) {
      this.selectedUser = user;
      this.isOpenDeleteUser = true;
    },
    openUserDialog(user) {
      this.selectedUser = user;
      this.isOpenUpdateUser = true;
    },
    closeUserDialog() {
      this.selectedUser = undefined;
      this.isOpenUpdateUser = false;
    },
    refetchUsers() {
      this.isLoading = true;
      this.fetchUsers();
    },
    fetchUsers(
      filter = {
        per_page: 20,
        order_by: this.descOrder ?? this.sortType,
        search: this.searchValue,
      },
    ) {
      this.$store
        .dispatch(FETCH_USERS, filter)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.notify('danger', 'Tenemos problemas para cargar los usuarios');
        });
    },
    deleteUser() {
      let adminCount = 0;
      if (
        this.selectedUser.status === USER_STATUS_UNVERIFIED &&
        (!this.selectedUser.roles.filter((role) => role.name === 'admin') ||
          adminCount > 1)
      ) {
        this.$store
          .dispatch(DELETE_USER, this.selectedUser)
          .then(() => {
            this.notify('success', 'Se ha eliminado el usuario con éxito');
            this.refetchUsers();
            this.closeDeleteUserDialog();
          })
          .catch((error) => {
            this.notify('danger', error.response.data.message);
          });
      } else {
        this.notify(
          'danger',
          'No se puede eliminar este usuario debe asignar otro administrador',
        );
      }
    },

    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    handleUserOrders(id, cant) {
      if (cant !== 0) {
        this.$router.push(`usuarios/ordenes/${id}`);
      }
    },
    resendUserVerification(e, email) {
      e.target.setAttribute('disabled', true);
      this.$store
        .dispatch(RESEND_VERIFICATION, email)
        .then(() => {
          this.notify('success', 'Se ha enviado la invitacion correctamente');
          e.target.removeAttribute('disabled');
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para enviar la invitacion');
          e.target.removeAttribute('disabled');
        });
    },
    isValidDate(date) {
      let now = new Date();
      let expiration = new Date(date);
      return expiration > now;
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_USERS, {
        page: this.page,
        per_page: this.perPage,
      });
      this.isLoading = false;
    },
    getRoleLabel(roleName) {
      const roleLabels = {
        user: 'Usuario',
        admin: 'Administrador',
        partner: 'Partner',
      };
      return roleLabels[roleName] || roleName;
    },
  },
};
</script>

<style>
.status-inactive {
  background-color: #a94c4c;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
}

.container-partners {
  padding: 0px !important;
}
</style>
